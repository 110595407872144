import { Partners } from 'app/core/components/StaticSections'
import { useWindowSize } from 'app/core/hooks'
import { Image } from 'blitz'
import { FC, useState } from 'react'
import { Button } from 'silicon-design-system'
import styled from 'styled-components'
import { Banner } from './Banner'

const SecondWinnerBox = styled.div`
  background: linear-gradient(
    91.59deg,
    #ffffff -9.62%,
    #3eebbe -9.62%,
    #4ec1f3 43.19%,
    #68fcd6 100%
  );
  border-radius: 16px;
`
const FirstWinnerBox = styled.div`
  background: linear-gradient(88.11deg, #ea2121 -26.77%, #fbbf24 47.67%, #fc7168 92.33%);
  border-radius: 16px;
`

const ThirdWinnerBox = styled.div`
  background: linear-gradient(
    110.98deg,
    #09f2e5 0%,
    #c5bbdc 30.97%,
    #9814af 71.32%,
    #535fff 100.59%
  );
  border-radius: 16px;
`

const Winner: FC = () => {
  const boxStyles =
    'relative flex flex-col items-center justify-center overflow-hidden drop-shadow-superShadow'

  const { width } = useWindowSize()
  const x = width ?? 0

  const first = (
    <FirstWinnerBox
      className={`${x >= 860 ? 'mt-4' : ''} ${boxStyles} h-[187px] w-full xsm:w-[315px]`}
    >
      <div>
        <div className="mb-3 text-center font-rflex font-bold">
          <span className="text-4xl">1</span>
          <span className="text-lg">st</span> <span className="text-4xl">PLACE</span>
        </div>
        <span className="font-rflex text-2xl font-light">5 Juta Rupiah</span>
      </div>
    </FirstWinnerBox>
  )

  const second = (
    <SecondWinnerBox className={`${boxStyles} h-[187px] w-full xsm:w-[315px]`}>
      <div>
        <div className="mb-3 text-center font-rflex font-bold">
          <span className="text-4xl">2</span>
          <span className="text-lg">nd</span> <span className="text-4xl">PLACE</span>
        </div>
        <span className="font-rflex text-2xl font-light">3 Juta Rupiah</span>
      </div>
    </SecondWinnerBox>
  )

  const third = (
    <ThirdWinnerBox className={`${boxStyles} h-[187px] w-full xsm:w-[315px]`}>
      <div>
        <div className="mb-3 text-center font-rflex font-bold">
          <span className="text-4xl">3</span>
          <span className="text-lg">rd</span> <span className="text-4xl">PLACE</span>
        </div>
        <span className="font-rflex text-2xl font-light">1 Juta Rupiah</span>
      </div>
    </ThirdWinnerBox>
  )

  return (
    <div className="my-16 text-center">
      <div>
        <h2 className="font-rflex text-xl font-bold md:text-4xl lg:text-5xl">
          Be on the top and win the prizes!
        </h2>
        <p className="mt-3 font-semibold text-milk vsm:text-base">
          Kenali cara bermain di Playground agar kamu semakin ahli!
        </p>
      </div>
      {x >= 860 && (
        <div className="mt-10 flex justify-center space-x-3">
          {second}
          {first}
          {third}
        </div>
      )}
      {x >= 550 && x < 860 && (
        <div className="mt-10">
          <div className="mx-auto w-fit">{first}</div>
          <div className="mt-3 flex items-center justify-center space-x-3">
            {second}
            {third}
          </div>
        </div>
      )}
      {x < 550 && (
        <div className="mt-10 flex flex-col items-center justify-center space-y-3">
          {first}
          {second}
          {third}
        </div>
      )}
    </div>
  )
}
const XPSystem: FC = () => (
  <div>
    <div className="text-center">
      <h2 className="font-rflex text-xl font-bold md:text-4xl lg:text-5xl">
        <span className="text-bananaLight">XP System</span> and COMPFEST{' '}
        <span className="text-bananaLight">Point</span>
      </h2>
      <p className="mx-auto mt-3 max-w-3xl font-semibold text-milk vsm:text-base">
        XP dan Point merupakan satuan yang dapat diperoleh dengan berpartisipasi aktif di Compfest
        dan dapat ditukar dengan hadiah-hadiah menarik!
      </p>
    </div>
    <div className="mt-10 flex flex-col items-center justify-center space-y-3 xl:flex-row xl:space-y-0 xl:space-x-3">
      <LSCard isLeaderboard />
      <LSCard />
    </div>
  </div>
)

const LSCard: FC<{ isLeaderboard?: boolean }> = ({ isLeaderboard = false }) => {
  const size = isLeaderboard
    ? 'h-[180px] w-[124px] lg:w-[168px] lg:h-[244px]'
    : 'h-[180px] w-[180px] lg:w-[219px] lg:h-[219px]'

  const redPath = isLeaderboard ? '/leaderboard' : 'supermarket'

  const btnText = isLeaderboard ? 'Lihat Leaderboard' : 'Kunjungi Toko'
  return (
    <div className="flex h-[530px] w-[290px] flex-col lg:h-[280px] lg:w-[600px] lg:flex-row ">
      <div className="flex h-2/4 w-full items-center justify-center rounded-3xl bg-card p-8 lg:h-auto lg:w-2/4 lg:justify-start">
        <div className={`relative ${size}`}>
          <Image
            src={`/assets/${isLeaderboard ? 'me-trophy.png' : 'me-shop.png'}`}
            layout="fill"
            alt="LeadShop"
          />
        </div>
      </div>
      <div className="relative z-5 -mt-10 flex h-2/4 w-full flex-col items-center justify-center rounded-3xl bg-cardSection py-9 px-6 lg:mt-0 lg:-ml-14 lg:h-auto lg:w-2/4">
        <div className="text-center font-rflex text-3xl font-bold">
          <p>COMPFEST</p>
          <p className="atlantic-acad">{isLeaderboard ? 'Leaderboard' : 'Shop'}</p>
        </div>
        <p className="max-w-[231px]text-center my-3 font-sans text-base font-semibold text-white2 md:py-0">
          {isLeaderboard
            ? 'Dapatkan XP, be on top, dan dapatkan hadiah fantastis di akhir Playground!'
            : 'Tukarkan Point dengan barang eksklusif Compfest,  tersedia di masa Playground!'}
        </p>
        <div>
          <Button text={btnText} onClick={() => (window.location.href = redPath)} />
        </div>
      </div>
    </div>
  )
}

export const Playground: FC<{ showPg: boolean; togglePg: () => unknown }> = ({
  showPg,
  togglePg,
}) => {
  return (
    <section>
      <Banner
        className="p-5 smm:p-8 sm:p-10"
        leftChild={
          <div className="max-w-[600px] text-center lg:max-w-[700px] lg:text-left">
            <h3 className="font-rflex text-xl font-bold md:text-4xl lg:text-5xl">PLAYGROUND</h3>
            <p className="mt-2 font-rflex text-xl font-bold text-bananaLight md:text-4xl lg:text-5xl">
              Earn Points & Get Prizes !
            </p>
            <p className="mt-8 font-sans text-sm font-semibold vsm:text-base md:mt-10">
              Rasakan asyiknya memainkan berbagai challenge dengan total hadiah mencapai 6 juta
              rupiah!
            </p>
          </div>
        }
        rightChild={
          <div className="relative mx-auto mb-8 h-[195px] w-[250px] smm:h-[235px] smm:w-[300px] md:mb-10 md:h-[345px] md:w-[440px] lg:mx-0 lg:mb-0">
            <Image src="/assets/me-pg-booth.svg" alt="CF Talks" layout="fill" />
          </div>
        }
        button={<Button text={`${showPg ? 'Tutup' : 'Lihat'} Playground`} onClick={togglePg} />}
        mode="left"
      />

      <div
        className={`${
          showPg ? 'flex flex-col space-y-10 sm:space-y-14' : 'hidden'
        } mt-5 box-border max-w-full overflow-x-auto overflow-y-hidden rounded-3xl bg-section px-4 py-6 text-center sm:px-8 sm:py-10 lg:py-12 lg:px-10`}
      >
        <div>
          <h2 className="font-rflex text-xl font-bold md:text-4xl lg:text-5xl">How to Play</h2>
          <p className="mt-3 font-semibold text-milk vsm:text-base">
            Kenali cara bermain di Playground agar kamu semakin ahli!
          </p>
          <div className="mt-5 overflow-x-auto">
            <div className="relative h-70 w-[calc(100%)] min-w-[700px] md:h-82 lg:h-100">
              {/* <Image src="/assets/how-to-play.svg" layout="fill" alt="how to play" /> */}
              <Image src="/me/htp.svg" layout="fill" alt="how to play" />
            </div>
          </div>
        </div>
        <Winner />
        <XPSystem />
        <div id="company-partners">
          <Partners secs={['company partners']} myPartners={PARTNERS} />
        </div>
      </div>
    </section>
  )
}

const PARTNERS = {
  'company partners': {
    S: [
      {
        name: 'Accenture',
        href: '',
        path: '/me/companies/accenture.png',
      },
      {
        name: 'Andalin',
        href: '',
        path: '/me/companies/andalin.png',
      },
      {
        name: 'Bhumi Varta Technology',
        href: '',
        path: '/me/companies/bhumi-varta-technology.png',
      },
      {
        name: 'BNI',
        href: '',
        path: '/me/companies/bni.png',
      },
      {
        name: 'Dewaweb',
        href: '',
        path: '/me/companies/dewaweb.png',
      },
      {
        name: 'EComindo',
        href: '',
        path: '/me/companies/ecomindo.png',
      },
      {
        name: 'GDP Labs',
        href: '',
        path: '/me/companies/gdp-labs.png',
      },
      {
        name: 'Mandiri',
        href: '',
        path: '/me/companies/mandiri.png',
      },
      {
        name: 'Mastercard',
        href: '',
        path: '/me/companies/mastercard.png',
      },
      {
        name: 'Passhouses',
        href: '',
        path: '/me/companies/pashouses.png',
      },
      {
        name: 'Suitmedia',
        href: '',
        path: '/me/companies/suitmedia.png',
      },
    ],
  },
}
