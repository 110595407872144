import { FC } from 'react'
import { Carousel } from 'app/acadcompe/components/Carousel/Carousel'

export const Images: FC = () => {
  return (
    <section>
      <div className="flex flex-col items-center justify-center font-rflex">
        <div className="mb-10 text-center text-3xl font-bold md:mb-12 md:text-4xl lg:text-6xl">
          <h2 className="text-bananaLight ">It{`'`}s FREE</h2>
          <p className="text-2xl  text-white md:text-3xl lg:text-5xl">
            to Explore, Learn, & Have Fun!
          </p>
          <p className="mt-5 max-w-[900px] font-sans text-lg font-semibold md:text-xl lg:text-2xl">
            Main Event adalah acara puncak COMPFEST yang akan diisi dengan berbagai macam acara
            menarik dan bermanfaat.
          </p>
        </div>
        <div className="relative">
          <Carousel images={[...Array.from({ length: 17 }, (_, i) => `/me/mep/${i + 1}.png`)]} />
        </div>
      </div>
    </section>
  )
}
