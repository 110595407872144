import styled, { keyframes } from 'styled-components'

export const gradient = keyframes`
0% {
  background-position: 0% 50%;
}
25% {
  background-position: 50% 50%;
}
50% {
  background-position: 100% 50%;
}
75% {
  background-position: 50% 50%;
}
100% {
  background-position: 0% 50%;
}
`

export const GradientEdge = styled.div`
  background: linear-gradient(-45deg, #fec166, #ff96a5, #a19afe, #68fcd6, #ff8bd5);
  background-size: 200% 200%;
  animation: ${gradient} 10s ease infinite;
  width: 100%;
  border-radius: 48px 48px 0 0;
  padding: 8px;
  height: 120vh;
  @media (min-width: 480px) {
    height: calc(120vh + 8rem);
  }
`

export const ChildGradient = styled.div`
  width: 100%;
  height: 120vh;
  @media (min-width: 480px) {
    height: calc(120vh + 7.5rem);
  }
  border-radius: 48px 48px 0 0;
  background: linear-gradient(106.48deg, #1c1c28 0%, #363649 100%);
  overflow: hidden;
`

export const NotGradientEdge = styled.div`
  background-size: 200% 200%;
  width: 100%;
  border-radius: 48px 48px 0 0;
  padding: 8px;
  height: 100vh;
  @media (min-width: 480px) {
    height: calc(100vh + 8rem);
  }
`

export const NotChildGradient = styled.div`
  width: 100%;
  height: 100vh;
  @media (min-width: 480px) {
    height: calc(100vh + 7.5rem);
  }
  border-radius: 48px 48px 0 0;
  overflow: hidden;
`

export const RadialBottom = styled.div`
  background: linear-gradient(transparent, #28293d);
  margin-top: 0px;
  @media (min-width: 480px) {
    margin-top: -120px;
  }
  width: 100%;
  height: 122px;
`
