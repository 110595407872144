import { FC } from 'react'
import Image from 'next/image'
import { Disclosure, Transition } from '@headlessui/react'
import QuestionBox from 'app/faq/components/QuestionBox'

import { xcelerateFaq as fq } from 'app/xcelerate/constants/faq'

const Faq: FC<{ faqs?: { question: string; answer: string }[]; feat?: string }> = ({
  faqs,
  feat,
}) => {
  const faq = faqs ?? fq
  const faqFirst = faq.length > 7 ? faq.slice(0, 7) : faq
  const faqSecond = faq.length > 7 ? faq.slice(7, faq.length) : []

  return (
    <div>
      <div className="w-full">
        <div className="mb-5 flex w-full items-center justify-center lg:space-x-9">
          <div className="relative hidden h-44 w-44 lg:block">
            <Image
              src="/icons/FAQ Icon (Message).png"
              alt="icon"
              layout="fill"
              className="relative"
              quality={100}
            />
          </div>
          <div className="flex flex-col items-center justify-center gap-2">
            <p className="text-center font-rflex text-5xl font-bold">
              Need some <span className="text-yellow-400">help</span>?
            </p>
            <p className="text-center  font-sans font-light">
              Temukan berbagai pertanyaan serta jawaban seputar {feat ?? 'Xcelerate'} di sini!
            </p>
          </div>
          <div className="relative hidden h-44 w-44 lg:block">
            <Image
              src="/icons/FAQ Icon (Bell).png"
              alt="icon"
              layout="fill"
              className="relative"
              quality={100}
            />
          </div>
        </div>
        <div
          className={`mx-auto w-full rounded-2xl bg-[#2D2F45] p-2 shadow-2xl ${
            faq.length <= 7 && 'pb-7'
          }`}
        >
          {faqFirst.map(({ question, answer }, idx) => {
            return <QuestionBox key={idx} question={question} answer={answer} />
          })}
          {faq.length > 7 && (
            <Disclosure as="div" className="mt-2">
              {({ open }) => (
                <>
                  <Transition
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                  >
                    <Disclosure.Panel>
                      {faqSecond.map(({ question, answer }, idx) => {
                        return <QuestionBox key={idx} question={question} answer={answer} />
                      })}
                    </Disclosure.Panel>
                  </Transition>

                  <div className="my-5 w-full px-5">
                    <Disclosure.Button className="flex w-full items-center justify-center rounded-lg bg-white bg-opacity-10 py-2 text-sm font-medium text-white hover:bg-white hover:bg-opacity-20 ">
                      {open ? <span>Tutup </span> : <span>Lihat lebih lanjut </span>}
                      <div className="relative h-10 w-10">
                        <Image
                          src="/icons/chevronDown.svg"
                          alt="icon"
                          layout="fill"
                          className={`${open ? 'relative rotate-180 transform' : ''}  relative`}
                          quality={100}
                        />
                      </div>
                    </Disclosure.Button>
                  </div>
                </>
              )}
            </Disclosure>
          )}
        </div>
      </div>
    </div>
  )
}

export default Faq
