import React, { FC, useState } from 'react'
import { motion, AnimatePresence, AnimateSharedLayout } from 'framer-motion'
import { Image } from 'blitz'
import { useWindowSize } from 'app/core/hooks'
import Left from 'public/icons/arrow-left-short.svg'
import Right from 'public/icons/arrow-right-short.svg'
import { Button } from 'silicon-design-system'

export const Carousel: FC<{ images: any[] }> = ({ images }) => {
  const size = useWindowSize()
  const width = size.width ?? 0
  const isSmm = (size.width ?? 0) >= 400
  const isXsm = (size.width ?? 0) >= 480
  const isSm = (size.width ?? 0) >= 640
  const isMd = (size.width ?? 0) >= 768
  const isLg = (size.width ?? 0) >= 1024

  const [page, setPage] = useState<number>(0)
  const variants = {
    slide: ({ page, i }) => {
      const level1 = Math.abs(page - i) == 1 || (page == 0 && i == 4) || (page == 4 && i == 0)
      const right1 = page == 0 ? i == 4 : page - i == 1
      const right2 = page == 0 ? i == 3 : page - i == 2
      const left1 = page == 4 ? i == 0 : page - i == -1
      const left2 = page == 4 ? i == 1 : page - i == -2
      // margin for every breakpoint
      const marginRight1 =
        width >= 1100
          ? 160
          : isLg
          ? 130
          : width >= 880
          ? 110
          : isMd
          ? 90
          : isSm
          ? 80
          : isXsm
          ? 55
          : isSmm
          ? 50
          : 40
      const marginLeft1 = -marginRight1

      const marginRight2 =
        width >= 1100
          ? 300
          : isLg
          ? 260
          : width >= 880
          ? 220
          : isMd
          ? 170
          : isSm
          ? 150
          : isXsm
          ? 110
          : isSmm
          ? 90
          : 80
      const marginLeft2 = -marginRight2
      return {
        zIndex: page == i ? 2 : level1 ? 1 : 0,
        x:
          page == i
            ? 0
            : right1
            ? marginRight1
            : left1
            ? marginLeft1
            : right2
            ? marginRight2
            : left2
            ? marginLeft2
            : page == 1
            ? marginRight2
            : marginLeft2,
        scale: page == i ? 1 : level1 ? 0.8 : 0.6,
        opacity: page == i ? 1 : level1 ? 0.75 : 0.5,
      }
    },
  }
  const spring = {
    velocity: 2,
    ease: 'easeIn',
  }
  const buttonClass = `grid grid-cols-1 place-items-center rounded-md p-2 bg-card `
  const btnSize = 16
  const shadow: string = 'shadow-[0_0_40px_4px_rgba(0,0,0,0.025)] shadow-[#7366fe]'

  // image size based on breakpoint
  const imgWidth = isLg ? 680 : isMd ? 600 : isSm ? 500 : isXsm ? 400 : 280
  const imgHeight = isLg ? 380 : isMd ? 342 : isSm ? 285 : isXsm ? 223 : 165
  return (
    <AnimateSharedLayout>
      <AnimatePresence>
        <div className="relative flex h-[250px] justify-center xsm:h-[320px] sm:h-[380px] md:h-[450px] lg:h-[480px]">
          {images.map((imgUrl, i) => {
            const isSelected = i === page
            return (
              <>
                <motion.div
                  key={i + imgUrl}
                  custom={{ page, i }}
                  variants={variants}
                  initial={false}
                  animate="slide"
                  transition={spring}
                  className={`${
                    isSelected ? shadow : ''
                  } absolute h-[165px] w-[280px] rounded-3xl  xsm:h-[223px] xsm:w-[400px] sm:h-[285px] sm:w-[500px] md:h-[342px] md:w-[600px] lg:h-[380px] lg:w-[680px]`}
                  onClick={() => setPage(i)}
                >
                  <Image
                    className={`rounded-3xl bg-white`}
                    src={imgUrl}
                    alt={'documentation'}
                    width={imgWidth}
                    height={imgHeight}
                    objectFit="cover"
                  />
                </motion.div>
                <div
                  className={`bottom-0 flex flex-row justify-center gap-x-5 py-5 ${
                    page == i ? 'absolute' : 'hidden'
                  }`}
                >
                  <Button
                    onClick={() => {
                      const prev = i == 4 ? 0 : i + 1
                      setPage(prev)
                    }}
                    midIcon={<Left />}
                    variant="secondary"
                    p={8}
                  />
                  <Button
                    onClick={() => {
                      const next = i == 0 ? 4 : i - 1
                      setPage(next)
                    }}
                    midIcon={<Right />}
                    variant="secondary"
                    p={8}
                  />
                  {/* <button
                    className={buttonClass}
                    onClick={() => {
                      const prev = i == 4 ? 0 : i + 1
                      setPage(prev)
                    }}
                  >

                    <Image
                      src="/icons/arrow-left-short.svg"
                      height={btnSize}
                      width={btnSize}
                      alt="left arrow"
                    />
                  </button>
                  <button
                    className={buttonClass}
                    onClick={() => {
                      const next = i == 0 ? 4 : i - 1
                      setPage(next)
                    }}
                  >
                    <Image
                      src="/icons/arrow-right-short.svg"
                      height={btnSize}
                      width={btnSize}
                      alt="right arrow"
                    />
                  </button> */}
                </div>
              </>
            )
          })}
        </div>
      </AnimatePresence>
    </AnimateSharedLayout>
  )
}
