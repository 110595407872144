export const SPONSORS = {
  L: [
    {
      path: '/me/sponsors/L/mastercard.png',
      name: 'Mastercard',
      href: 'https://www.mastercard.co.id/id-id.html/',
    },
    {
      path: '/me/sponsors/L/kemenperin.png',
      name: 'Mastercard',
      href: 'https://www.kemenperin.go.id/',
    },
  ],
  M: [
    {
      path: '/me/sponsors/M/dewaweb.png',
      name: 'Dewaweb',
      href: 'https://www.dewaweb.com/',
    },
    {
      path: '/me/sponsors/M/bank-mandiri.png',
      name: 'Mandiri',
      href: 'https://www.bankmandiri.co.id/',
    },
    {
      path: '/me/sponsors/M/nahtuh.png',
      name: 'Nahtuh',
      href: 'https://nahtuh.com/',
    },
  ],
  S: [
    {
      path: '/me/sponsors/S/aws.png',
      name: 'AWS',
      href: 'https://aws.amazon.com/',
    },
    {
      path: '/me/sponsors/S/bni.png',
      name: 'BNI',
      href: 'https://www.bni.co.id/',
    },
    {
      path: '/me/sponsors/S/convey.png',
      name: 'Convey',
      href: 'https://www.convey.co.id/',
    },
    {
      path: '/me/sponsors/S/gcp.png',
      name: 'Google Cloud Platform',
      href: 'https://cloud.google.com/',
    },
    {
      path: '/me/sponsors/S/logitech.png',
      name: 'Logitech',
      href: 'https://www.logitech.com/',
    },
    {
      path: '/me/sponsors/S/schoters.png',
      name: 'Schoters',
      href: 'https://www.schoters.com/',
    },
    {
      path: '/partners/sponsors/S/tcp.png',
      name: 'TCP',
      href: '/',
    },
    {
      path: '/partners/sponsors/S/palayumandiri.jpeg',
      name: 'Palayu Mandiri',
      href: '/',
    },
    {
      path: '/partners/sponsors/S/nmr.png',
      name: 'NMR by Namira',
      href: 'https://nmrbynamira.com/',
    },
    {
      path: '/partners/sponsors/S/binariciptasolusi.png',
      name: 'EzzyApps',
      href: '/',
    },
  ],
}

export const MEDIA_PARTNER = {
  M: [
    {
      path: '/me/medpar/M/connext.png',
      name: 'Connext',
      href: '/',
    },
    {
      path: '/me/medpar/M/event-aja.jpeg',
      name: 'Event Aja',
      href: '/',
    },
    {
      path: '/me/medpar/M/explore-depok.png',
      name: 'Explore Depok',
      href: '/',
    },
    {
      path: '/me/medpar/M/inilah-koran.png',
      name: 'Inilah Koran',
      href: '/',
    },
    {
      path: '/me/medpar/M/jogja-punya-acara.png',
      name: 'Jogja Punya Acara',
      href: '/',
    },
    {
      path: '/me/medpar/M/makassar-event.png',
      name: 'Makassar Event',
      href: '/',
    },
    {
      path: '/me/medpar/M/medan-seminar.jpg',
      name: 'Medan Seminar',
      href: '/',
    },
    {
      path: '/me/medpar/M/raria-media.png',
      name: 'Raria Media',
      href: '/',
    },
    {
      path: '/me/medpar/M/sagara.png',
      name: 'Sagara Technology',
      href: '/',
    },
    {
      path: '/me/medpar/M/scraf-media.png',
      name: 'Scraf Media',
      href: '/',
    },
    {
      path: '/me/medpar/M/trax-fm-semarang.jpg',
      name: 'Trax FM Semarang',
      href: '/',
    },
    {
      path: '/me/medpar/M/ule.png',
      name: 'Ule',
      href: '/',
    },
    {
      path: '/me/medpar/M/yuk-coding.png',
      name: 'Yuk Coding',
      href: '/',
    },
  ],
}

export const PARTNERS = {
  sponsor: SPONSORS,
  'media partner': MEDIA_PARTNER,
}
