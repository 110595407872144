import { Disclosure, Transition } from '@headlessui/react'
import ChevronRightIcon from 'public/icons/chevronDown.svg'
import { FC } from 'react'
import { classNames } from 'utils/functions'
import DOMPurify from 'dompurify'

interface QuestionBoxProps {
  question: string
  answer: string
}
const QuestionBox: FC<QuestionBoxProps> = ({ question, answer }) => {
  return (
    <div className="mx-5 mt-5 max-w-full basis-1/2">
      <Disclosure>
        {({ open }) => (
          <div
            className={classNames(
              'flex w-full flex-col rounded-xl border-4 border-solid bg-black/10 p-2',
              open ? 'border-white/10' : 'border-transparent',
            )}
          >
            <Disclosure.Button className="flex w-full flex-row justify-between rounded-xl border-white/10 p-4 hover:bg-white/10">
              <p
                className="w-full text-left font-semibold"
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(question) }}
              />
              <ChevronRightIcon
                className={classNames('duration-100 ease-in', open && 'rotate-180 transform')}
              />
            </Disclosure.Button>

            <Transition
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Disclosure.Panel className="p-3">
                <p
                  className="font-medium"
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(answer) }}
                />
              </Disclosure.Panel>
            </Transition>
          </div>
        )}
      </Disclosure>
    </div>
  )
}

export default QuestionBox
