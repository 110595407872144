import { Image } from 'blitz'
import { FC } from 'react'
import styled from 'styled-components'
import { Button } from 'silicon-design-system'
import { gradient } from 'app/core/components/Hero/GrandLauching/Gradient'

const Border = styled.div`
  padding: 8px;
  background: linear-gradient(180deg, rgba(40, 41, 61, 0) 77.6%, #28293d 100%),
    linear-gradient(
      151.66deg,
      #fec166 0.11%,
      #ff96a5 10.06%,
      #a19afe 35.16%,
      #ff8bd5 62.98%,
      #68fcd6 91.38%
    );
  mix-blend-mode: normal;
  border-radius: 32px;
  background-size: 200% 200%;
  animation: ${gradient} 10s ease infinite;
`

const Container = styled.div`
  background: linear-gradient(106.48deg, #1c1c28 0%, #363649 100%);
  box-shadow: 0px 100px 126px rgba(0, 0, 0, 0.15), 0px 41.7776px 52.6398px rgba(0, 0, 0, 0.107828),
    0px 22.3363px 28.1437px rgba(0, 0, 0, 0.0894161), 0px 12.5216px 15.7772px rgba(0, 0, 0, 0.075),
    0px 6.6501px 8.37913px rgba(0, 0, 0, 0.0605839),
    0px 2.76726px 3.48674px rgba(0, 0, 0, 0.0421718);
  border-radius: 24px;
`

const EVENTS = ['me-cftalks', 'me-jobfair', 'me-playground', 'me-performance']

export const Hero: FC<{ expandPg?: () => unknown }> = ({ expandPg }) => {
  return (
    <>
      <Border className="shadow-2xl shadow-[#A19AFE]">
        <Container className="w-full px-4 py-6 sm:px-16 md:py-12 md:px-28">
          <div className="relative mx-auto h-11 w-32 md:h-20 md:w-82">
            <Image src="/assets/cf-logo.svg" layout="fill" alt="COMPFEST" />
          </div>
          <h1 className="atlantic-acad sm:text-5x text-xsl text-center font-rflex font-bold uppercase smm:text-3xl lg:text-7xl">
            MAIN EVENT
          </h1>
          <div className="text-center font-rflex font-bold">
            <span className="text-xsl smm:text-2xl md:text-4xl">12 November 2022</span>
            <div className="flex items-center justify-center">
              <div className="relative h-8 w-8 md:h-10 md:w-10">
                <Image src="/icons/location_on.svg" layout="fill" alt="location" />
              </div>
              <span className="text-base text-white2 smm:text-xl md:text-3xl">
                in Balairung University of Indonesia
              </span>
            </div>
          </div>

          <div className="my-5 flex flex-wrap justify-evenly sm:my-8">
            {EVENTS.map((event) => (
              <div
                className="relative h-28 w-28 msm:h-32 msm:w-32 xsm:h-40 xsm:w-40 md:h-44 md:w-44"
                key={event}
              >
                <Image src={`/assets/${event}.svg`} layout="fill" alt="CFTalks" />
              </div>
            ))}
          </div>
          <div className="flex flex-col items-center  justify-center space-y-2 text-xs vsm:text-sm smx:mx-auto  smx:max-w-xs lgx:space-y-0 md:w-full md:max-w-none md:flex-row md:flex-wrap md:justify-center md:space-x-4">
            <Button
              text="Download Guidebook"
              onClick={() => {
                window.open('/me/booklet.pdf', '_blank')
              }}
              variant="ghost"
              size="full"
              _responsive={{
                md: {
                  w: 'fit-content',
                },
              }}
            />

            <Button
              text="Daftar"
              onClick={() => (window.location.href = '/registration')}
              size="full"
              _responsive={{
                md: {
                  w: 'fit-content',
                },
              }}
            />

            <Button
              text="Lihat Perusahaan Partner"
              variant="ghost"
              onClick={() => {
                expandPg?.()
                setTimeout(() => {
                  window.location.href = '#company-partners'
                }, 100)
              }}
              size="full"
              _responsive={{
                md: {
                  w: 'fit-content',
                },
              }}
            />
          </div>
        </Container>
      </Border>
    </>
  )
}
