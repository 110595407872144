import { Image } from 'blitz'
import { FC } from 'react'
import { Button } from 'silicon-design-system'
import { Banner } from './Banner'

export const Jobfair: FC = () => {
  return (
    <section>
      <Banner
        className="px-5 smm:px-8 md:px-20"
        rightChild={
          <div className="max-w-[500px] text-center lg:max-w-[600px] lg:text-right">
            <h3 className="ml-4 font-rflex text-xl font-bold uppercase text-white md:text-4xl lg:text-5xl">
              Job & Intern Fair
            </h3>
            <p className="mt-2 font-rflex text-xl font-bold text-mintLight md:text-4xl lg:text-5xl">
              Discover Tech Jobs
            </p>
            <p className="my-6 font-sans text-sm font-semibold vsm:text-base md:mt-10">
              Cari lowongan pekerjaanmu di bidang IT dari berbagai perusahaan untuk Internship
              ataupun Full-Time!
            </p>
            <div className="flex flex-wrap justify-center gap-2 overflow-auto lg:justify-end">
              <div className="flex w-[160px] rounded-lg bg-cardSection p-2">
                <span className="font-rflex text-xl font-bold text-gumLight">50+</span>
                <span className="ml-1 font-sans text-xs font-semibold text-white2">
                  Lowongan pekerjaan dibuka
                </span>
              </div>
              {/* <div className="flex w-[160px] rounded-lg bg-cardSection p-2">
                <span className="font-rflex text-xl font-bold text-bananaLight">200+</span>
                <span className="ml-1 font-sans text-xs font-semibold text-white2">
                  Lowongan pekerjaan dibuka
                </span>
              </div>
              <div className="flex w-[160px] rounded-lg bg-cardSection p-2">
                <span className="font-rflex text-xl font-bold text-mintLight">150+</span>
                <span className="ml-1 font-sans text-xs font-semibold text-white2">
                  Lowongan pekerjaan dibuka
                </span>
              </div> */}
            </div>
          </div>
        }
        leftChild={
          <div className="relative mx-auto mt-8 h-[235px] w-[300px] md:mt-10 md:h-[345px] md:w-[440px] lg:mx-0 lg:mt-0">
            <Image src="/assets/me-jobfair-booth.svg" alt="CF Talks" layout="fill" />
          </div>
        }
        // button={
        //   <Button
        //     text="Lihat Job & Intern Fair"
        //     onClick={() => (window.location.href = '/jobfair')}
        //   />
        // }
        mode="left"
      />
    </section>
  )
}
