export const SPONSORS = {
  L: [
    {
      path: '/partners/sponsors/L/mastercard.png',
      name: 'Mastercard',
      href: 'https://www.mastercard.co.id/id-id.html/',
    },
  ],
  M: [
    {
      path: '/partners/sponsors/M/dewaweb.png',
      name: 'Dewaweb',
      href: 'https://www.dewaweb.com/',
    },
    {
      path: '/partners/sponsors/M/mandiri.svg',
      name: 'Mandiri',
      href: 'https://www.bankmandiri.co.id/',
    },
    {
      path: '/partners/sponsors/M/sagara-technology.png',
      name: 'Sagara Technology',
      href: 'https://sagaratechnology.com/en',
    },
  ],
  S: [
    {
      path: '/partners/sponsors/S/aws.png',
      name: 'AWS',
      href: 'https://aws.amazon.com/',
    },
    {
      path: '/partners/sponsors/S/bni.png',
      name: 'BNI',
      href: 'https://www.bni.co.id/',
    },
    {
      path: '/partners/sponsors/S/convey.png',
      name: 'Convey',
      href: 'https://www.convey.co.id/',
    },
    {
      path: '/partners/sponsors/S/gcp.png',
      name: 'Google Cloud Platform',
      href: 'https://cloud.google.com/',
    },
    {
      path: '/partners/sponsors/S/logitech.png',
      name: 'Logitech',
      href: 'https://www.logitech.com/',
    },
  ],
}

export const MEDIA_PARTNER = {
  XS: [
    {
      path: '/partners/media-partners/XS/1_L_Media Group Network.png',
      name: 'Media Group Network',
      href: '/',
    },
    {
      path: '/partners/media-partners/XS/2_L_Metro TV.png',
      name: 'Metro TV',
      href: '/',
    },
    {
      path: '/partners/media-partners/XS/3_L_Media Indonesia.png',
      name: 'Media Indonesia',
      href: '/',
    },
    {
      path: '/partners/media-partners/XS/4_L_Medcom.id.png',
      name: 'Medcom.id',
      href: '/',
    },
    {
      path: '/partners/media-partners/XS/5_L_RTV.png',
      name: 'RTV',
      href: '/',
    },
    {
      path: '/partners/media-partners/XS/6_L_TRANS7.png',
      name: 'Trans7',
      href: '/',
    },
    {
      path: '/partners/media-partners/XS/8_L_Okezone.com.png',
      name: 'Okezone.com',
      href: '/',
    },
    {
      path: '/partners/media-partners/XS/9_L_Sindonews.com.png',
      name: 'Sindonews.com',
      href: '/',
    },
    {
      path: '/partners/media-partners/XS/10_L_iNews.id.png',
      name: 'iNews.id',
      href: '/',
    },
    {
      path: '/partners/media-partners/XS/11_L_IDX Channel.png',
      name: 'IDX Channel',
      href: '/',
    },
    {
      path: '/partners/media-partners/XS/12_L_Celebrities.id.png',
      name: 'Celebrities.id',
      href: '/',
    },
    {
      path: '/partners/media-partners/XS/13_L_Sportstars.id.png',
      name: 'Sportstars.id',
      href: '/',
    },
    {
      path: '/partners/media-partners/XS/14_L_Koran Sindo.png',
      name: 'Koran Sindo',
      href: '/',
    },
    {
      path: '/partners/media-partners/XS/15_L_Kompasiana.png',
      name: 'Kompasiana',
      href: '/',
    },
    {
      path: '/partners/media-partners/XS/16_L_Tribunnews.com.png',
      name: 'Tribunnews.com',
      href: '/',
    },
    {
      path: '/partners/media-partners/XS/17_L_DailySocial.id.png',
      name: 'DailySocial.id',
      href: '/',
    },
    {
      path: '/partners/media-partners/XS/18_L_GNFI.png',
      name: 'GNFI',
      href: '/',
    },
    {
      path: '/partners/media-partners/XS/19_L_Indonesia Belajar.png',
      name: 'Indonesia Belajar',
      href: '/',
    },
    {
      path: '/partners/media-partners/XS/20_L_Kok Bisa.png',
      name: 'Kok Bisa',
      href: '/',
    },
    {
      path: '/partners/media-partners/XS/21_L_Sonora Jakarta.png',
      name: 'Sonora Jakarta',
      href: '/',
    },
    {
      path: '/partners/media-partners/XS/22_L_PR FM.png',
      name: 'PR FM',
      href: '/',
    },
    {
      path: '/partners/media-partners/XS/23_L_Global Radio.png',
      name: 'Global Radio',
      href: '/',
    },
    {
      path: '/partners/media-partners/XS/24_L_Provoke Youth Club.png',
      name: 'Provoke Youth Club',
      href: '/',
    },
    {
      path: '/partners/media-partners/XS/25_L_Marketeers.png',
      name: 'Marketeers',
      href: '/',
    },
    {
      path: '/partners/media-partners/XS/26_L_UP Station.png',
      name: 'UP Station',
      href: '/',
    },
    {
      path: '/partners/media-partners/XS/27_L_IN Today Media.png',
      name: 'IN Today Media',
      href: '/',
    },
  ],
  XXS: [
    {
      path: '/partners/media-partners/XXS/1_M_detikcom.png',
      name: 'detikcom',
      href: 'https://www.detik.com/',
    },
    {
      path: '/partners/media-partners/XXS/2_M_CDC UI.png',
      name: 'CDC UI',
      href: '',
    },
    {
      path: '/partners/media-partners/XXS/3_M_Liputan Bogor.png',
      name: 'Liputan Bogor',
      href: '',
    },
    {
      path: '/partners/media-partners/XXS/4_M_Event Jakarta.png',
      name: 'Event Jakarta',
      href: '',
    },
    {
      path: '/partners/media-partners/XXS/5_M_HIMSI UNAIR.png',
      name: 'HIMSI UNAIR',
      href: '',
    },
    {
      path: '/partners/media-partners/XXS/6_M_csrelatedcompetitions.png',
      name: 'csrelatedcompetitions',
      href: '',
    },
    {
      path: '/partners/media-partners/XXS/7_M_Media Startup.png',
      name: 'Media Startup',
      href: '',
    },
    {
      path: '/partners/media-partners/XXS/8_M_AnakStartup.id.png',
      name: 'AnakStartup.id',
      href: '',
    },
    {
      path: '/partners/media-partners/XXS/9_M_Dunia Coding.png',
      name: 'Dunia Coding',
      href: '',
    },
    {
      path: '/partners/media-partners/XXS/10_M_Geekhunter.png',
      name: 'Geekhunter',
      href: '',
    },
    {
      path: '/partners/media-partners/XXS/11_M_Girlskode.png',
      name: 'Girlskode',
      href: '',
    },
    {
      path: '/partners/media-partners/XXS/12_M_Pintar Ngoding.png',
      name: 'Pintar Ngoding',
      href: '',
    },
    {
      path: '/partners/media-partners/XXS/13_M_Teknologi.id.png',
      name: 'Teknologi.id',
      href: '',
    },
    {
      path: '/partners/media-partners/XXS/14_M_Beritabaik.id.png',
      name: 'Beritabaik.id',
      href: '',
    },
    {
      path: '/partners/media-partners/XXS/15_M_Doteens.png',
      name: 'Doteens',
      href: '',
    },
    {
      path: '/partners/media-partners/XXS/16_M_Frix Media.png',
      name: 'Frix Media',
      href: '',
    },
    {
      path: '/partners/media-partners/XXS/17_M_Hipwee.png',
      name: 'Hipwee',
      href: '',
    },
    {
      path: '/partners/media-partners/XXS/18_M_MileniaNews.png',
      name: 'MileniaNews',
      href: '',
    },
    {
      path: '/partners/media-partners/XXS/19_M_NetralNews.com.png',
      name: 'NetralNews.com',
      href: '',
    },
    {
      path: '/partners/media-partners/XXS/20_M_Berisik Radio.png',
      name: 'Berisik Radio',
      href: '',
    },
    {
      path: '/partners/media-partners/XXS/21_M_BPRS ERA FM UNJ.png',
      name: 'BPRS ERA FM UNJ',
      href: '',
    },
    {
      path: '/partners/media-partners/XXS/22_M_EBS FM UNHAS.png',
      name: 'EBS FM UNHAS',
      href: '',
    },
    {
      path: '/partners/media-partners/XXS/23_M_UMN Radio.png',
      name: 'UMN Radio',
      href: '',
    },
    {
      path: '/partners/media-partners/XXS/24_M_Event Banget.png',
      name: 'Event Banget',
      href: '',
    },
    {
      path: '/partners/media-partners/XXS/25_M_EventNasional.idn.png',
      name: 'EventNasional.idn',
      href: '',
    },
    {
      path: '/partners/media-partners/XXS/26_M_Info.Seminar.png',
      name: 'Info.Seminar',
      href: '',
    },
    {
      path: '/partners/media-partners/XXS/27_M_InfoEventNasional.id.png',
      name: 'InfoEventNasional.id',
      href: '',
    },
    {
      path: '/partners/media-partners/XXS/28_M_Info Lomba.png',
      name: 'Info Lomba',
      href: '/',
    },
    {
      path: '/partners/media-partners/XXS/29_InfoWebinar.Event.png',
      name: 'InfoWebinar.Event',
      href: '/',
    },
    {
      path: '/partners/media-partners/XXS/30_M_Kompetisi Mahasiswa.png',
      name: 'Kompetisi Mahasiswa',
      href: '/',
    },
    {
      path: '/partners/media-partners/XXS/31_M_Pusat Seminar.png',
      name: 'Pusat Seminar',
      href: '/',
    },
    {
      path: '/partners/media-partners/XXS/32_M_Seputar Seminar.png',
      name: 'Seputar Seminar',
      href: '/',
    },
    {
      path: '/partners/media-partners/XXS/33_M_WebinarIndonesia.info.png',
      name: 'WebinarIndonesia.info',
      href: '/',
    },
    {
      path: '/partners/media-partners/XXS/34_M_Maukuliah.png',
      name: 'Maukuliah',
      href: '/',
    },
    {
      path: '/partners/media-partners/XXS/35_M_SEVIMA.png',
      name: 'SEVIMA',
      href: '/',
    },
    {
      path: '/partners/media-partners/XXS/36_M_infoseminarworkshop.id.png',
      name: 'infoseminarworkshop.id',
      href: '/',
    },
    {
      path: '/partners/media-partners/XXS/37_M_Radio Pelita Kasih.png',
      name: 'Radio Pelita Kasih',
      href: '/',
    },
    {
      path: '/partners/media-partners/XXS/38_M_Radio PPI Dunia.png',
      name: 'Radio PPI Dunia',
      href: '/',
    },
    {
      path: '/partners/media-partners/XXS/39_Radio PTPN Solo.png',
      name: 'Radio PTPN Solo',
      href: '/',
    },
    {
      path: '/partners/media-partners/XXS/40_M_London School Radio.png',
      name: 'London School Radio',
      href: '/',
    },
    {
      path: '/partners/media-partners/XXS/41_M_Yuk Coding.png',
      name: 'Yuk Coding',
      href: '/',
    },
    {
      path: '/partners/media-partners/XXS/42_M_Connext.png',
      name: 'Connext',
      href: '/',
    },
    {
      path: '/partners/media-partners/XXS/43_M_EventAja.png',
      name: 'EventAja',
      href: '/',
    },
    {
      path: '/partners/media-partners/XXS/44_M_Raria Media.png',
      name: 'Raria Media',
      href: '/',
    },
    {
      path: '/partners/media-partners/XXS/45_M_Update Lomba Event.png',
      name: 'Update Lomba Event',
      href: '/',
    },
    {
      path: '/partners/media-partners/XXS/46_M_Trax FM Semarang.png',
      name: 'Trax FM Semarang',
      href: '/',
    },
    {
      path: '/partners/media-partners/XXS/47_M_Medan Seminar.png',
      name: 'Medan Seminar',
      href: '/',
    },
    {
      path: '/partners/media-partners/XXS/48_M_Makassar Event.png',
      name: 'Makassar Event',
      href: '/',
    },
    {
      path: '/partners/media-partners/XXS/49_M_Jogja Punya Acara.png',
      name: 'Jogja Punya Acara',
      href: '/',
    },
    {
      path: '/partners/media-partners/XXS/50_M_Explore Depok.png',
      name: 'Explore Depok',
      href: '/',
    },
    {
      path: '/partners/media-partners/XXS/51_M_Scarf Media.png',
      name: 'Scarf Media',
      href: '/',
    },
    {
      path: '/partners/media-partners/XXS/52_M_InilahKoran.png',
      name: 'InilahKoran',
      href: '/',
    },
    {
      path: '/partners/media-partners/XXS/53_M_Sagara Technology.png',
      name: 'Sagara Technology',
      href: '/',
    },
  ],
}

export const LEARNING_MEDIA_PARTNER = {
  // M: [],
}

export const PARTNERS = {
  sponsor: SPONSORS,
  'media partner': MEDIA_PARTNER,
  'learning media partner': LEARNING_MEDIA_PARTNER,
}
