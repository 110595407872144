import { Partners } from 'app/core/components/StaticSections'
import { ME_FAQ } from 'app/main-event/constants'
import { PARTNERS } from 'app/main-event/constants/partners'
import ContactPerson from 'app/xcelerate/components/Landing/ContactPerson'
import Faq from 'app/xcelerate/components/Landing/Faq'
import { FC, useState } from 'react'
import { CfTalks } from './CfTalks'
import { Hero } from './Hero'
import { Images } from './Images'
import { Jobfair } from './Jobfair'
// import { Lineup } from './Lineup'
import { Playground } from './Playground'
import { RundownMap } from './RundownMap'

export const MELanding: FC = () => {
  const [showPg, setShowPg] = useState(false)
  const expandPg = () => setShowPg(true)
  const togglePg = () => setShowPg((prev) => !prev)

  return (
    <main className="my-10">
      <section className="mb-28">
        <Hero expandPg={expandPg} />
      </section>
      <div className="flex flex-col space-y-12 md:space-y-16 lg:space-y-20">
        <Images />
        <RundownMap />
        {/* <Lineup /> */}
        <CfTalks />
        <Jobfair />
        <Playground showPg={showPg} togglePg={togglePg} />
        <Faq feat="Main Event" faqs={ME_FAQ} />
        <section id="partners">
          <Partners myPartners={PARTNERS} />
        </section>
        <ContactPerson
          contactPerson={{
            line: 'vxnskadom',
            wa: [{ no: '6281298837402', nama: 'Venska' }],
          }}
        />
      </div>
    </main>
  )
}
