import { FC, useState } from 'react'
import Image from 'next/image'
import { Button } from 'silicon-design-system'
import ShareIcon from 'public/icons/share.svg'
import WhatsappIcon from 'public/icons/whatsapp-white-outline.svg'
import EnvelopeIcon from 'public/icons/envelope.svg'
import Line from 'public/icons/line-gray.svg'
import { ShareModal } from 'app/core/components/Modal'
import { Router } from 'blitz'

interface ContactPersonProps {
  contactPerson: {
    email?: string
    wa?: any[]
    line?: string
  }
}
const ContactPerson: FC<ContactPersonProps> = ({ contactPerson: { email, wa, line } }) => {
  const [showModalState, setShowModalState] = useState(false)

  return (
    <div className="my-5 w-full rounded-2xl bg-[#2D2F45] p-5 shadow-2xl lg:h-[379px] lg:px-[70px] lg:py-[49px]">
      <div className="flex flex-col-reverse items-center justify-center lg:flex-row">
        <div className="flex w-full flex-col items-center justify-center space-y-[30px]">
          <div>
            <p className="text-center font-rflex text-5xl font-bold">How can we help you?</p>
            <p className="text-md mt-3 text-center font-medium opacity-80">
              Ada masalah? Yuk hubungi contact person kami!
            </p>
          </div>
          <div className="hidden flex-wrap items-center justify-center gap-2 lg:flex">
            {line && (
              <Button
                text={line}
                size="unset"
                leftIcon={<Line />}
                variant="secondary"
                onClick={() => window.open('http://line.me/ti/p/~' + line)}
              />
            )}
            {email && (
              <Button
                text={email}
                size="unset"
                leftIcon={<EnvelopeIcon />}
                variant="secondary"
                onClick={() => window.open('mailto:' + email)}
              />
            )}
            {!!wa &&
              wa!.map((noWa) => {
                return (
                  <Button
                    key={noWa['no']}
                    text={noWa['nama'] == '' ? noWa['no'] : `Hubungi ${noWa['nama']}`}
                    size="unset"
                    leftIcon={<WhatsappIcon />}
                    variant="secondary"
                    onClick={() => window.open('http://wa.me/' + noWa['no'])}
                  />
                )
              })}
          </div>
          <div className="flex w-full flex-col items-center justify-center gap-6 lg:hidden">
            {line && (
              <Button
                text={line}
                size="full"
                leftIcon={<Line />}
                variant="secondary"
                onClick={() => window.open('http://line.me/ti/p/~' + line)}
              />
            )}
            {email && (
              <Button
                text={email}
                size="full"
                leftIcon={<EnvelopeIcon />}
                variant="secondary"
                onClick={() => window.open('mailto:' + email)}
              />
            )}
            {!!wa &&
              wa!.map((noWa) => {
                return (
                  <Button
                    key={noWa['no']}
                    text={noWa['nama'] == '' ? noWa['no'] : `Hubungi ${noWa['nama']}`}
                    size="full"
                    leftIcon={<WhatsappIcon />}
                    variant="secondary"
                    onClick={() => window.open('http://wa.me/' + noWa['no'])}
                  />
                )
              })}
          </div>
          <div className="w-full lg:hidden">
            <ShareModal
              showModal={showModalState}
              setShowModal={setShowModalState}
              link={Router.asPath}
            />
            <Button
              text="Bagikan informasi ini"
              size="full"
              leftIcon={<ShareIcon />}
              onClick={() => setShowModalState(true)}
            />
          </div>
          <div className="hidden w-full items-center justify-center lg:flex">
            <ShareModal
              showModal={showModalState}
              setShowModal={setShowModalState}
              link={Router.asPath}
            />
            <Button
              text="Bagikan informasi ini"
              size="1/2"
              leftIcon={<ShareIcon />}
              onClick={() => setShowModalState(true)}
            />
          </div>
        </div>
        <div className="relative flex h-[150px] w-[188px] items-center justify-center lg:h-[292.03px] lg:w-[366px]">
          <Image
            src="/icons/Icon CP.png"
            alt="background"
            layout="fill"
            className="relative"
            quality={100}
          />
        </div>
      </div>
    </div>
  )
}

export default ContactPerson
