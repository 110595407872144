type XcelerateFaqItemType = {
  question: string
  answer: string
}

export const xcelerateFaq: XcelerateFaqItemType[] = [
  {
    question:
      'Jika saya mendaftar XCelerate, apakah saya akan otomatis terdaftar pada seminar dan workshop pada batch tersebut?',
    answer:
      'Tidak. Anda dapat mendaftar pada salah satu mata acara saja. Jika Anda ingin mendaftar pada seminar dan workshop, Anda harus mendaftar dua kali!',
  },
  {
    question: 'Apakah pendaftaran XCelerate dipungut biaya?',
    answer:
      'Tidak! Anda hanya perlu membayar commitment fee yang mana uang Anda akan dikembalikan secara penuh setelah acara selesai dan jika Anda menghadiri acara.',
  },
  {
    question:
      'Apakah nilai skor kuis saya pada pendaftaran akan memengaruhi kemungkinan saya mendapatkan e-certificate?',
    answer:
      'Tidak! Skor kuis hanya akan kami gunakan untuk screening pendaftar. Jika Anda dikonfirmasi diterima sebagai peserta, Anda dipastikan akan mendapatkan e-certificate selama Anda menghadiri acara',
  },
  {
    question: 'Apakah saya boleh menanyakan hal lain di luar materi ketika sesi QnA?',
    answer:
      'Tentu boleh! Peserta diperbolehkan menanyakan apapun kepada speaker asal tidak mengandung SARA atau hal-hal yang menyinggung lain, ya!',
  },
  {
    question: 'Apakah saya harus masuk ke dalam Whatsapp Group setelah mendaftar?',
    answer:
      'Kami akan menyampaikan informasi-informasi tambahan melalui WhatsApp Group. Jika tidak ingin ketinggalan informasi, para peserta diharapkan untuk join WAG yang disediakan, ya!',
  },
]
