/* eslint-disable @next/next/no-img-element */
import { PARTNERS } from 'app/core/constants/partners'
import { Image, Link } from 'blitz'
import { FC } from 'react'
import styles from 'app/core/components/StaticSections/partner.module.css'
interface IPartner {
  name: string
  href: string
  path: string
}

interface PartnersComponentProps {
  title: string
  partners: {
    S?: IPartner[]
    M?: IPartner[]
    L?: IPartner[]
  }
}

export const PartnersComponent: FC<PartnersComponentProps> = ({ title, partners }) => {
  const sizes = Object.keys(partners)

  if (sizes.length === 0) return null

  let marginStyling = 'mt-2 md:mt-4'
  if (title === 'sponsor') marginStyling = 'mt-0'

  return (
    <section className={`rounded-3xl bg-section px-4 pt-6 pb-4 ${marginStyling}`}>
      <div className="mx-4 text-center vsm:mx-6 xsm:mx-10 md:mx-20">
        <h2 className="mb-4 font-rflex text-lg font-bold uppercase md:mb-8">{title}</h2>
      </div>
      <div className="flex flex-col space-y-8">
        {sizes.map((size) => {
          const ourPartners = partners[size]
          let imgSize = 'h-[168px] '
          if (size === 'M') imgSize = 'h-[128px] '
          if (size === 'S') imgSize = 'h-[88px] '
          if (size === 'XS') imgSize = 'h-36'
          if (size === 'XXS') imgSize = 'h-20'

          if (!ourPartners) return null

          return (
            <div key={size} className="flex flex-wrap items-center justify-center gap-y-8 gap-x-8">
              {ourPartners.map(({ name, href, path }, i) => (
                <Link href={href} key={name + i}>
                  <a className={`relative`} target="_blank" rel="noreferrer">
                    {/* <Image src={path} alt={name} layout="fill" objectFit="contain" /> */}
                    <img src={path} alt={name} className={`${imgSize} object-contain`} />
                  </a>
                </Link>
              ))}
            </div>
          )
        })}
        <div className="flex flex-wrap items-center justify-center"></div>
      </div>
    </section>
  )
}

export const Partners: FC<{ myPartners?: any; secs?: string[] }> = ({ myPartners, secs }) => {
  const sections = secs ?? ['updatedsponsor', 'sponsor', 'media partner']

  const components = sections.map((section) => {
    if (section == 'updatedsponsor') {
      return <UpdatedPartner key={section} />
    }
    if (section == 'media partner') {
      const partners = PARTNERS['media partner']
      if (!partners) return null
      return (
        <PartnersComponent
          key={section}
          title={section}
          partners={{
            M: partners.XXS,
            L: partners.XS,
          }}
        />
      )
    }
    const partners = myPartners ? myPartners[section] : PARTNERS[section]
    if (!partners) return null
    return <PartnersComponent key={section} title={section} partners={partners} />
  })

  return <section>{components}</section>
}

export const PartnersMedia: FC = () => {
  const sections = ['sponsor', 'media partner', 'learning media partner']

  const components = sections.map((section) => {})

  return <section>{components}</section>
}

export const UpdatedPartner: FC = () => {
  return (
    <section className="mb-8 grid grid-cols-1 gap-4 md:mb-12 md:grid-cols-2 xl:gap-10">
      <div
        className={`${styles.kartu}  flex w-full flex-col items-center justify-around rounded-3xl bg-section p-10 `}
        id="poweredby"
      >
        <h4
          style={{ letterSpacing: '0.2em' }}
          className="mb-4 text-center font-rflex text-3xl font-bold text-white2 sm:text-4xl md:mb-6 md:text-5xl "
        >
          POWERED BY
        </h4>
        <Link href="https://www.mastercard.co.id/id-id.html/" key={'updatedmastercard'}>
          <a className={`relative`} target="_blank" rel="noreferrer">
            <img
              src="/partners/sponsors/L/mastercard.png"
              alt={'mastercard'}
              className={`mx-auto object-contain`}
            />
          </a>
        </Link>{' '}
      </div>
      <div
        className={`${styles.kartu}  flex w-full flex-col items-center justify-around rounded-3xl bg-section p-10 `}
      >
        <h4
          style={{ letterSpacing: '0.2em' }}
          className="mb-4 text-center font-rflex text-3xl font-bold text-white2 sm:text-4xl md:mb-6 md:text-5xl "
        >
          PRESENTED BY
        </h4>
        <Link href="https://www.bi.go.id/id/default.aspx" key={'updatedbankindonesia'}>
          <a className={`relative`} target="_blank" rel="noreferrer">
            <img
              src="/partners/sponsors/L/bankindonesia.png"
              alt={'bank indonesia'}
              className={`mx-auto object-contain`}
            />
          </a>
        </Link>{' '}
      </div>
    </section>
  )
}
