import { SkeletonWrapper } from 'app/core/components/Skeleton'
import { TwoTabs } from 'app/core/components/Tab'
import { RUNDOWN } from 'app/main-event/constants'
import getRundown from 'app/main-event/queries/me/getRundown'
import { downloadMap } from 'app/main-event/utils'
import { Image, Link, useQuery } from 'blitz'
import { FC, Suspense, useRef, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Button } from 'silicon-design-system'

export type RundownItemTypes = 'regular' | 'limited' | 'registered' | 'soldout'

type RundownItemProps = {
  isPg?: boolean
  startTime: Date
  endTime: Date
  textRightTitle: string
  textRightSubtitle?: string
  type?: RundownItemTypes
  details?: {
    text: string
    action: () => unknown
  }
}

const RundownItem: FC<RundownItemProps> = ({
  isPg = false,
  startTime,
  endTime,
  type = 'regular',
  textRightTitle,
  textRightSubtitle,
  details,
}) => {
  const start = `${startTime.getHours().toString().padStart(2, '0')}:${startTime
    .getMinutes()
    .toString()
    .padStart(2, '0')}`
  const end = `${endTime.getHours().toString().padStart(2, '0')}:${endTime
    .getMinutes()
    .toString()
    .padStart(2, '0')}`

  const now = new Date()
  const isNow = startTime <= now && now <= endTime
  const isPassed = endTime < now

  let icon = ''
  let timeStyles = 'border-[#0AA4E7] bg-transparent text-white'
  let textColor = ''
  let text = ''

  if (type === 'soldout') {
    icon = 'patch-exclamation.svg'
    timeStyles = 'border-black3 bg-black3 text-white3'
    textColor = 'text-white3'
    text = 'Sold Out'
  }

  if (type === 'limited') {
    icon = 'patch-exclamation-banana.svg'
    timeStyles = 'border-banana bg-transparent text-banana'
    textColor = 'text-banana'
    text = 'Limited Seats!'
  }

  if (type === 'registered') {
    icon = 'check-circle-mint.svg'
    timeStyles = 'border-mint bg-transparent text-mint'
    textColor = 'text-mint'
    text = 'Registered!'
  }

  if (isNow) {
    icon = 'broadcast-red.svg'
    timeStyles = 'border-peach bg-peach text-white'
    textColor = 'text-peach'
    text = 'Now!'
  }

  if (isPassed) {
    icon = 'patch-exclamation.svg'
    timeStyles = 'border-black3 bg-black3 text-white3'
    textColor = 'text-white3'
    text = 'Passed'
  }

  return (
    <li className="flex space-x-5 sm:space-x-8">
      <div className="min-w-[130px]">
        <div
          className={`flex items-center justify-center rounded-xl border-[1px] p-2 font-sans text-sm font-normal xsm:text-base ${timeStyles}`}
        >
          <span>
            {start} - {end}
          </span>
        </div>
        {!!icon && (
          <div className="mt-2 flex items-center justify-center">
            <Image height={20} width={20} alt="icon" src={`/icons/${icon}`} />
            <span className={`ml-1 font-sans text-base font-normal ${textColor}`}>{text}</span>
          </div>
        )}
      </div>

      <div>
        <div className="font-sans text-base font-bold">
          <span className={`${isPg ? 'text-bananaLight' : 'text-grape'}`}>{textRightTitle}</span>
          {textRightSubtitle && (
            <>
              <br />
              <span className={`${isPg ? 'text-mintLight' : 'text-white'}`}>
                {textRightSubtitle}
              </span>
            </>
          )}
        </div>
        <div>
          {details && (
            <div
              className="mt-2 flex cursor-pointer items-center font-sans text-base font-normal text-blueberry"
              onClick={details.action}
            >
              <span>{details.text}</span>
            </div>
          )}
        </div>
      </div>
    </li>
  )
}

const RundownCfTalks: FC = () => {
  const [cfs] = useQuery(getRundown, null)

  return (
    <>
      {RUNDOWN.map((r, i) => {
        const cf = cfs.find((c) => c.i === r.i)

        return <RundownItem key={r.textRightTitle + i} {...r} type={cf?.type ?? 'regular'} />
      })}
    </>
  )
}

const Rundown: FC = () => {
  return (
    <div className="mx-auto max-w-[672px] overflow-hidden rounded-3xl drop-shadow-rundown">
      <div className="bg-cardSection py-6 px-16 md:px-20 lg:px-[100px]">
        <h2 className="text-center font-rflex text-xl font-bold md:text-3xl">Main Event Rundown</h2>
      </div>
      <div className="flex flex-col space-y-8 overflow-x-auto bg-card px-5 py-8 md:p-10">
        {/* {RUNDOWN.map((r, i) => (
          <RundownItem key={r.textRightTitle + i} {...r} />
        ))} */}
        <Suspense
          fallback={
            <SkeletonWrapper>
              <Skeleton count={8} className="mb-2 h-12" />
            </SkeletonWrapper>
          }
        >
          <RundownCfTalks />
        </Suspense>
      </div>
    </div>
  )
}

export const RundownMap: FC = () => {
  const [tab, setTab] = useState(1)

  return (
    <section>
      <TwoTabs
        tab={tab}
        setTab={setTab}
        leftChild={<span className="font-rflex font-bold">See The Rundown</span>}
        rightChild={<span className="font-rflex font-bold">Explore The Map</span>}
      />
      <div className="mt-5 rounded-3xl bg-card p-5 sm:p-6 md:p-8 lg:p-10">
        {tab === 1 && <Rundown />}
        {tab === 2 && (
          <div>
            <h2 className="mb-3 text-center font-rflex text-xl font-bold sm:mb-6 sm:text-3xl lg:text-5xl">
              Main Event Map
            </h2>
            <div className="relative h-[180px] w-[calc(100%)] smd:h-[420px]">
              <Image src="/me/map-v1.png" alt="Main Event Map" layout="fill" />
            </div>

            <div className="mx-auto mt-4 w-fit">
              <Button text="Download Map" onClick={downloadMap} />
            </div>

            {/* <div className="mx-auto mt-3 w-fit">
              <Button text="Download Map" onClick={downloadMap} />
            </div> */}
          </div>
        )}
      </div>
    </section>
  )
}
