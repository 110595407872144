import { Dispatch, FC, ReactNode, SetStateAction } from 'react'
import { motion } from 'framer-motion'

import { classNames } from 'utils/functions'

interface TabProps {
  tab: number
  setTab: Dispatch<SetStateAction<number>>
  leftChild: ReactNode
  rightChild: ReactNode
}

export const TwoTabs: FC<TabProps> = ({ tab, setTab, leftChild, rightChild }) => {
  const tabVariants = {
    leftVar: {
      x: '0%',
      transition: {
        duration: 0.5,
        type: 'spring',
        bounce: 0.25,
      },
    },
    rightVar: {
      x: '100%',
      transition: {
        duration: 0.5,
        type: 'spring',
        bounce: 0.25,
      },
    },
  }

  return (
    <div className="relative h-12 w-full rounded-lg bg-[#00000010]">
      <motion.div
        className={classNames(
          'absolute z-10 flex h-full w-1/2 items-center justify-center rounded-lg bg-[#FFFFFF10] p-1',
        )}
        variants={tabVariants}
        animate={tab === 1 ? 'leftVar' : 'rightVar'}
      >
        <div className="h-full w-full rounded-lg bg-gradient-to-t from-[#3643FC] to-[#5561FF]" />
      </motion.div>
      <div
        className={classNames(
          'absolute left-0 z-20 flex h-full w-1/2 items-center justify-center rounded-lg bg-transparent',
          tab === 2 && 'cursor-pointer hover:bg-[#323341]',
        )}
        onClick={() => setTab(1)}
      >
        {/* <p className="font-semibold">Batch 1{isMd && ' - Product Management'}</p> */}
        {leftChild}
      </div>
      <div
        className={classNames(
          'absolute right-0 z-20 flex h-full w-1/2 items-center justify-center rounded-lg bg-transparent',
          tab === 1 && 'cursor-pointer hover:bg-[#323341]',
        )}
        onClick={() => setTab(2)}
      >
        {/* <p className="font-semibold">Batch 2{isMd && '- UI/UX'}</p> */}
        {rightChild}
      </div>
    </div>
  )
}
