export const downloadMap = () => {
  fetch('/me/map-v1.png', {
    method: 'GET',
    headers: {},
  })
    .then((response) => {
      response.arrayBuffer().then(function (buffer) {
        const url = window.URL.createObjectURL(new Blob([buffer]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'map.png') //or any other extension
        document.body.appendChild(link)
        link.click()
      })
    })
    .catch(() => {})
}
